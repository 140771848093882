<template>
	<div>
		<div class="box">
			<div class="title_box flex">
				<Title title="公告消息列表" />
				<!-- <ul class="status_ul2 flex">
					<li :class="status == item ? 'active' : ''" v-for="(item, index) in statusUl" :key="index"
						@click="statusShow(item)">
						{{ item }}
					</li>
				</ul> -->
			</div>
			<div class="ct_box">
				<div class="search-status_box flex"></div>
				<table class="table">
					<thead>
						<tr>
							<td>内容</td>
							<!-- <td>状态</td> -->
							<td>发送时间</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in tableData" :key="index">
							<td>{{ item.content }}</td>
							<!-- <td>未读</td> -->
							<td>{{ item.publish_time_text }}</td>
						</tr>
					</tbody>
				</table>
				<!-- 分页器 -->
				<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
			</div>
		</div>
	</div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
export default {
	components: {
		Title,
	},
	mounted() {
		this.listFn()
		this.getUserInfo()
	},
	data() {
		return {
			time: '',
			resource: '',
			statusUl: ['全部', '未读', '已读'],
			status: '全部',
			page: 1,
			total_page: 0, //分页总页数
			count: 0, //分页总页数
			tableData: []
		}
	},
	methods: {
		statusShow(item) {
			this.status = item
		},
		LinkShow() {
			this.Link = true
		},
		wordOrderShow() {
			this.WorkOrder = true
		},
		pageChange(pageVal) {
			this.page = pageVal
			this.listFn()
		},
		copy() {
			//参数是复制的内容
			//.then()中的函数是复制成功和复制失败的两个回调函数
			this.$copyText(this.status).then(
				(e) => {
					return this.$message({
						message: '复制成功',
						type: 'success',
					})
				},
				(err) => {
					return this.$message({
						message: '复制失败',
						type: 'warning',
					})
				}
			)
		},
		listFn() {
			let data = {}
			data.page = this.page
			data.limit = 20
			this.curlPost('/api/users/notice', data).then(res => {
				if (res.data.code) {
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData = res.data.data.list
				}
			})
		},
		// 刷新用户信息
		getUserInfo() {
			this.curlGet('/api/user/info').then((res) => {
				if (res.data.code) {
					this.$user_info = res.data.data
					this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/MessageCenter.scss';
</style>